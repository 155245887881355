.lockdropContainer {
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  min-height: calc(100vh - 68px);
}
.contentContainer {
  display: flex;
  gap: 120px;
  justify-content: space-between;
  align-items: baseline;
}
.leftColumn {
  font-family: Poppins;
  flex: 1;
  width: 100%;
  max-width: 750px;
  color: #fff;
}
.leftColumn h1 {
  font-family: Poppins;
  font-size: 80px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.leftIntro {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 74px;
}
.lockableToken {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.bossPointsContent {
  display: flex;
  gap: 10px;
  align-items: center;
}
.bpts {
  color: #0eb5b2;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}
.bossPoints {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.rightColumn {
  width: 100%;
  max-width: 530px;
  /* padding-top: 40px; */
}
.connectWallet {
  background-image: linear-gradient(96deg, #4179ff -1%, #00ffe8 95%);
  font-family: Poppins !important;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}
.inputWrapper {
  width: 440px;
}
.walletBarContainer {
  padding: 15px 15px 15px 20px;
  border-radius: 20px;
  background-color: #313133;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.walletDetails {
  display: flex;
  gap: 20px;
  align-items: center;
}
.walletName {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.button {
  font-size: 15px;
  padding: 10px 25px;
  border-radius: 10px;
  background-color: #0eb5b2;
  cursor: pointer;
  border-color: transparent;
  color: white;
}

.lockTokenContainer {
  padding: 50px;
  border-radius: 20px;
  background-color: #313133;
  font-family: Poppins;
}
.lockTokenTitle {
  background-image: linear-gradient(98deg, #4179ff -1%, #00ffe8 52%);
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
}
.tokenInputContainer {
  display: flex;
  gap: 15px;
}
.tokenInput {
  all: unset;
  width: 303px;
  height: 50px;
  padding: 0 20px;
  border-radius: 10px;
  background-color: #202023;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-family: Poppins;
}
.maxButton {
  padding: 13px 40px 12px;
  border-radius: 10px;
  border: solid 1px #7b7b7b;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: transparent;
}
.lockTokenText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.tokenInputErrorMessage {
  color: rgb(208, 57, 57) !important ;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 16px;
  margin-bottom: 40px;
}
.lockTokenEarning {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fadb5a;
}
.potentialEarning {
  color: #70cf97;
  margin-top: 16px;
  margin-bottom: 40px;
}

.myLockdropdownContainer {
  padding: 70px 0;
}
.lockdropContent {
  display: flex;
  justify-content: left;
  margin-bottom: 16px;
  line-height: normal;
  letter-spacing: normal;
  align-items: center;
}
.refreshButton {
  width: 153px;
  height: 50px;
  margin-left: 60px;
  border-radius: 10px;
  text-align: center;
  background-color: #7b7b7b;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  border: none;
}
.refreshButton:hover {
  background-color: #7b7b7bc2;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.refreshButton:active {
  background-color: #4b4b4b;
  transform: translateY(1px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.myLockdropTitle {
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  font-weight: 700;
  font-family: Poppins;
}
.myLockdropDesc {
  width: 100%;
  max-width: 740px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.earningCardWrapper {
  margin-top: 40px;
  display: grid;
  gap: 46px;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-column-gap: 46px;
  grid-row-gap: 46px;
  max-width: 100%;
  /* justify-content: space-between; */
}
.earningCardStyle {
  width: 100%;
  max-width: 315px;
}
.spinnerPageCover {
  /* position: fixed; */
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.9);
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  scale: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moment {
  font-size: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-top: 20px;
}
.refreshIcon {
  margin-right: 10px;
}

@media (max-width: 1280px) {
  .lockdropContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
