.customButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.outlined {
  border: solid 1px #7b7b7b;
  background-color: transparent;
}
.contained {
  background-color: #0eb5b2;
  border: none;
}
.disabled {
  background-color: #7b7b7b;
  border: none;
}
