.earningCardContainer {
  padding: 40px;
  border-radius: 20px;
  background-color: #313133;
  font-family: Poppins;
  max-width: 315px;
  height: 446px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleText {
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.earningText {
  color: #fadb5a;
  margin-bottom: 35px;
}
.potentialEarning {
  color: #70cf97;
}
.duration {
  color: #fff;
  margin-bottom: 30px;
}

.descText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7b7b7b;
  text-align: center;
  margin-bottom: 5px;
}

.potentialContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
  flex: 1;
}
.earningsLable{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #7b7b7b;
  text-align: center;
  margin-bottom: 5px;
}
.earningsValue{
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  color: #70cf97;
  text-align: center;
}
.cardContentCont{
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
}
.refreshIcon {
  margin-right: 0px;
}
.refreshButton {
  width: 153px;
  height: 50px;
  margin-left: 60px;
  border-radius: 10px;
  text-align: center;
  background-color: #7b7b7b;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  border: none;
}
.resyncButton {
width: 30px;
height: 30px;
border-radius: 50%;
border: none;
background-color: #7b7b7b;
color: white;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
transition: background-color 0.3s;
}
.resyncButton:hover {
  background-color: #7b7b7bc2;
  transform: translateY(-1px);
}
.resyncButton:active {
  background-color: #4b4b4b;
  transform: translateY(1px);
}