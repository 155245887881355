.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 57px;
  margin-bottom: 37px;
}
.disconectModal{
  width: 190px;
  height: 50px;
  margin: 0 20px 37px 9px;
  padding: 15px 30px;
  display: flex;
  border-radius: 10px;
  background-color: #3d3d3f;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;

}
.btnContainer{
  display: flex;
 gap: 20px;
 align-items: center;
}
.addressbtn{
  padding: 15px 30px;
  border-radius: 10px;
  background-color: #3d3d3f;
  color: white;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  border: none;
}
.addressbtn:hover{
  background-color: #3d3d3fe4;
  color: white;
  transform: translateY(-1px);
}
.addressbtn:active{
  transform: translateY(1px);
  background-color: #3d3d3fc5;
}
.disconnectBtn{
  color: #7b7b7b;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.disconnectBtn:hover{
  color: #d5d5d5c2;
}
.disconnectBtn:active{
  color: #7b7b7b;
  transform: translateY(1px);
}