.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #121212;
  padding-left: 100px;
padding-right: 100px;
}
.copyrightText {
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.tMinus {
  color: #0eb5b2;
}

@media(max-width:1280px){
  .footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}