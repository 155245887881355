.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 9;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.9);
}
.modalBody {
  background-color: #3d3d3f;
  width: 600px;
  height: 430px;
  /* height: 570px; */
  /* height: 700px; */
  top: 400px;
  left: 50%;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  transform: translateX(-50%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.5);
  /* background-color: #fff; */
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 30;
  max-width: 850px;
  /* height: 425px; */
}
.header {
  display: flex;
  justify-content: center;
  margin-top: 27px;
  position: relative;
  font-size: 37px;
  width: 100%;
  z-index: 2;
}

.logoParentCont {
  display: flex;
  align-items: center;
  /* height: 85%; */
  margin: 25px 70px 40px 70px;
}
.logoParent {
  /* width: 80%; */
  margin: auto;
  /* height: 90%; */
}

.rowParent {
  display: flex;
  justify-content: space-between;
  text-align: center;
  /* height: 50%; */
  padding-bottom: 2%;
}
.whiteSpaceCard {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  /* width: 23%; */
  min-width: 200px;
  height: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.whiteSpaceCard:hover {
  border-radius: 20px;

  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.16);

  background-color: #fff;
}
.whiteSpaceCard:hover p {
  color: #606988;
}
.middleLogo {
  margin-left: 50px;
  margin-right: 50px;
}
/* .logoImg,
  .logoImg2 {
    width: 48%;
    height: 98px;
  } */

.rowParent2 {
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 50%;
  /* padding-bottom: 3%; */
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: auto;
}
.featureImage {
  width: 135px;
  height: 154px;
  margin-top: 60px;
}
.content h3 {
  margin-top: 50px;
  font-size: 25px;
  color: #606988;
  font-family: Poppins;
}
.content p {
  margin-top: 15px;
  font-size: 22px;
  color: #606988;

  font-family: Poppins;
  font-size: 18px;
}
.statusPara {
  color: #d7d7d7 !important;
  font-size: 25px !important;
  margin-top: 37px !important;
  font-weight: bold !important;
  margin-bottom: 73px;
}
.noWalletImg {
  width: 400px;
  margin-top: 70px;
}
.unAvailText {
  color: #c2cced;
  margin-top: 7px;
  font-size: 18px;
}
.connectedWalletBody {
  width: 85%;
  margin: auto;
  padding-bottom: 50px;
}
.bottomModal {
  justify-content: center;
  /* width: 600px;
  height: 70px; */
  padding: 27px 233px;
  border-radius: 20px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #202021;
}
.closeButton {
  justify-content: center;
  width: 135px;
  height: 45px;
  padding: 10px 40px 10px 40px;
  border-radius: 10px;
  background-color: #0eb5b2;
  font-family: Poppins;
  font-size: 15px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  border: none;
}
.closeButton:hover {
  background-color: #0eb5b2e4;
  transform: translateY(-1px);
}
.closeButton:active {
  background-color: #0eb5b2c5;
  transform: translateY(1px);
}

.walletName {
  color: #364575;
  font-size: 25px;
  margin-top: 40px;
  margin-bottom: 13px;
}
.DisconnectModal_walletImg {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-right: 20px;
  position: relative;
}
.DisconnectModal_walletID {
  line-break: anywhere;
  font-size: 16px;
  letter-spacing: 0.6px;
  color: #606988;
  flex: auto;
}
.copyAddrCont {
  margin-left: 47px;
  margin-top: 15px;
  cursor: pointer;
  max-width: 250px;
}
.copyIcon {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
.copyAddress {
  font-size: 14px;
  font-weight: 300;
  color: #8f97ae;
  display: flex;
  align-items: center;
}

.disconnectBtn {
  padding: 0 40px;
  margin-top: 35px;
  height: 58px;
  white-space: nowrap;
  border-radius: 50px;
  border: solid 1px #666f8c;
  font-size: 16px;
  font-family: Raleway;
  font-weight: 800;
  color: #666f8c;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s;
}

.disconnectBtn:hover {
  background-color: #8c8a8a19;
}
.disconnectBtn:active {
  box-shadow: 0 2px rgba(152, 152, 152, 0.3);
  transform: translateY(2px);
}
.disconnectBtnCont {
  display: flex;
  justify-content: center;
}
.copiedText {
  padding: 3px 6px;
  margin-left: 13px;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: rgb(238, 234, 234);
  font-weight: 500;
}
.hidden {
  opacity: 0;
}
.namiImg {
  width: 95px;
  height: 97px;
}
.eternlImg {
  width: 97px;
  height: 97px;
}
.yoroiImg {
  width: 98px;
  height: 86px;
}
.flintImg {
  width: 108px;
  height: 108px;
}
.typhoonImg {
  width: 120px;
  height: 87px;
}
.geroImg {
  width: 146px;
  height: 126px;
}

@media (max-height: 900px) {
  .modalBody {
    top: 60px;
  }
}

@media (max-width: 1400px) {
  /* .modalBody {
      width: 60%;
    }
  
    .featureImage {
      width: 140px;
      margin-top: 35px;
    }
    .noWalletImg {
      width: 300px;
      margin-top: 40px;
    }
    .content h3 {
      margin-top: 45px;
      font-size: 30px;
    }
    .content p {
      margin-top: 20px;
      font-size: 18px;
    }
    .statusPara {
      font-size: 30px !important;
      margin-top: 35px !important; 
    } */
  /* .DisconnectModal_walletID{
      margin-left: 25px;
      line-break: anywhere;
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0.2px;
      color: #0c0e16;
    } */
}
@media (max-width: 1100px) {
  /* .modalBody {
      width: 70%;
    }
    .statusPara{
      margin-bottom:20px;
  
    } */
}
.claimSuccessBody {
  top: 20%;
}
